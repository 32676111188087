<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div class="mb-12">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h1 class="mb-0">{{ $t('Affairs.pageTitle') }}</h1>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <core-search
                module="affairs"
                @update="onSearch()"
              />
            </v-col>
          </v-row>
          <core-filters
            :active-filters-count="activeFiltersCount"
            module="affairs"
            section="filters"
            class="mb-2"
            @update="onFilterUpdate()"
          />
          <core-filter-buttons
            @apply="onFilterUpdate()"
            @reset="onFilterReset()"
            :reset-label="$t('Affairs.resetLabel')"
          />
        </div>
        <affair-list
          :items="affairs"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :loading="loading"
          :items-per-page-options="itemsPerPageOptions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('affairs/setFilterValuesAndQueryFromQuery', { query: to.query })
    });
  },
  metaInfo () {
    return {
      title: this.$t('Affairs.pageTitle'),
    }
  },
  computed: {
    ...mapGetters('affairs', [
      'affairs',
      'serverItemsLength',
      'loading',
      'itemsPerPageOptions',
      'activeFiltersCount',
    ]),
    options: {
      get () {
        return this.$store.state.affairs.options
      },
      set (options) {
        this.$store.commit('affairs/SET_AFFAIRS_OPTIONS', options)
      },
    },
  },
  watch: {
    options: {
      async handler () {
        await this.getAffairs()
      },
      deep: true,
    },
  },
  async mounted () {
    await this.getFilterItems()
  },
  methods: {
    ...mapActions('affairs',
      [
        'getAffairs',
        'getFilterItems',
      ],
    ),
    onFilterUpdate () {
      this.resetListPage()
      this.getAffairs()
    },
    onFilterReset () {
      this.resetListPage()
      this.$store.commit('affairs/RESET_FILTER_VALUES')
      this.$store.commit('affairs/RESET_QUERY')
      this.$store.commit('affairs/SET_FILTER_EXPANDED', undefined)
      this.$store.dispatch('auth/resetSettings', { module: 'affairs' }, { root: true })
      this.getAffairs()
    },
    onSearch () {
      this.resetListPage()
      this.getAffairs()
    },
    resetListPage () {
      this.$store.commit('affairs/SET_AFFAIRS_PAGE', 1)
    },
  },
}
</script>
